import mixins from '@/mixins'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import mainFilter from '../../components/filter-results/index.vue'
import CountryFlag from 'vue-country-flag'

export default {
  name: 'tests-results',
  mixin: [mixins],
  components: {
    mainFilter,
    CountryFlag
  },
  data () {
    return {
      page: 1,
      showRemoveDialog: false,
      removingItem: null
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name !== 'tests-results-users') {
      this.setList([])
      this.changeSkip(0)
      this.changeListLength(0)
    }
    next()
  },
  computed: {
    ...mapGetters({
      list: 'tests/list',
      limit: 'tests/limit',
      skip: 'tests/skip',
      isListLoading: 'tests/isListLoading',
      listLength: 'tests/listLength'
    }),
    headers () {
      return [
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Picture',
          value: 'picture'
        },
        {
          text: 'Category',
          value: 'category',
          sort: (a, b) => {
            const nameA = a.data.title.toLowerCase()
            const nameB = b.data.title.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
        {
          text: 'Country',
          value: 'country',
          sort: (a, b) => {
            const nameA = a.data.slug.toLowerCase()
            const nameB = b.data.slug.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
        {
          text: 'Status',
          value: 'status',
          sort: (a, b) => {
            const nameA = a.data.title.toLowerCase()
            const nameB = b.data.title.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
      ]
    }
  },
  mounted () {
    if (!this.list.length) {
      this.fetchList()
    }
    if (this.list) {
      // if (this.skip !== 0) {
      //   this.page = Math.round(this.skip / this.limit) + 1
      // }
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'tests/GET_LIST'
    }),
    ...mapMutations({
      setList: 'tests/SET_LIST',
      changeSkip: 'tests/CHANGE_SKIP',
      changeListLength: 'tests/SET_LIST_LENGTH',
    }),
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList()
    },
    removeListItem () {
      this.remove(this.removingItem).then(() => {
        this.showRemoveDialog = false
        this.$toasted.success(this.$t('success_deleted'))
      })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    }
  },
  beforeDestroy () {
    // this.setList([])
  }
}
